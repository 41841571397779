import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API_URL } from '@konnektu/tokens';
import { Observable, tap } from 'rxjs';
import {
  CreateSegmentRequest,
  EditSegmentRequest,
  PaginatedResult,
  SegmentResponseDto,
  SegmentVersionDto
} from './models';

@Injectable({ providedIn: 'root' })
export class SegmentsService {
  private readonly http = inject(HttpClient);
  private readonly apiUrl = inject(API_URL);

  private readonly segmentsUrl = `${this.apiUrl}/segments`;

  allSegments(
    page?: number,
    pageSize?: number,
    orderBy?: 'CreatedOn' | 'CreatedOnDesc' | 'Name' | 'NameDesc',
    nameQuery?: string
  ): Observable<PaginatedResult<SegmentResponseDto>> {
    let params = new HttpParams();
    if (page) {
      params = params.append('page', page);
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize);
    }
    if (orderBy) {
      params = params.append('orderBy', orderBy);
    }
    if (nameQuery) {
      params = params.append('name', nameQuery);
    }
    return this.http
      .get<PaginatedResult<SegmentResponseDto>>(this.segmentsUrl, {
        params
      })
      .pipe(
        tap((result) => {
          for (const segment of result.items) {
            try {
              // segment query can be SQL string
              segment.query.text = JSON.parse(segment.query.text);
            } catch {}
          }
        })
      );
  }

  getByIds(ids: number[]) {
    const params = new HttpParams({ fromObject: { ids } });
    return this.http.get<PaginatedResult<SegmentResponseDto>>(
      this.segmentsUrl,
      { params }
    );
  }

  byId(segmentId: number): Observable<SegmentResponseDto> {
    return this.http.get<SegmentResponseDto>(
      `${this.segmentsUrl}/${segmentId}`
    );
  }

  segmentVersions(
    segmentId: number,
    page?: number,
    pageSize?: number
  ): Observable<PaginatedResult<SegmentVersionDto>> {
    const params =
      page && pageSize
        ? new HttpParams().appendAll({ page: page, pageSize: pageSize })
        : {};
    return this.http.get<PaginatedResult<SegmentVersionDto>>(
      `${this.segmentsUrl}/${segmentId}/versions`,
      { params: params }
    );
  }

  createSegment(
    payload: CreateSegmentRequest,
    raw = false
  ): Observable<SegmentResponseDto> {
    return this.http.post<SegmentResponseDto>(this.segmentsUrl, {
      ...payload,
      query: {
        ...payload.query,
        text: raw ? payload.query.text : JSON.stringify(payload.query.text)
      }
    });
  }

  editSegment(
    id: number,
    payload: EditSegmentRequest
  ): Observable<SegmentResponseDto> {
    return this.http.patch<SegmentResponseDto>(`${this.segmentsUrl}/${id}`, {
      ...payload,
      query: {
        ...payload.query,
        text: JSON.stringify(payload.query?.text)
      }
    });
  }

  updateSegmentMetadata(
    segmentId: number,
    metadataKey: string,
    newMetadata: any
  ) {
    return this.http.put(
      `${this.segmentsUrl}/${segmentId}/metadata/${metadataKey}`,
      newMetadata
    );
  }

  editRawSegment(
    id: number,
    {
      name,
      queryText
    }: {
      name?: string;
      queryText?: string;
    }
  ) {
    return this.http.patch<SegmentResponseDto>(`${this.segmentsUrl}/${id}`, {
      name,
      query: {
        text: queryText
      }
    });
  }
}
