import { BaseEntity } from '../BaseEntity';

export interface Query extends BaseEntity {
  Text: string;
  TypeId: number;
}

export enum QueryTypeIds {
  Postgres = 1,
  Hive
}
