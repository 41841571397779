import { BaseLookupEntity } from '../BaseLookupEntity';

export type CommunicationChannel = BaseLookupEntity;

export const CommunicationChannel = {
  Email: 1,
  Sms: 2,
  Push: 3,
  WhatsApp: 4,
  Viber: 5,
  Telegram: 6,
  Ivr: 7
};
