export enum CommunicationActivityTypeIds {
  NotSent = 1,
  Sent = 2,
  Delivered = 3,
  Opened = 4,
  Click = 5,
  Spam = 6,
  Unsubscribed = 7,
  Subscriptions = 8,
  Confirmations = 9,
  Dismiss = 10,
  Foreground = 11,
  Goals = 12,
  Expired = 13,
  Deleted = 14,
  UnknownStatus = 15
}

export enum CommunicationChannelIds {
  Email = 1,
  Sms = 2,
  Push = 3,
  WhatsApp = 4,
  Viber = 5,
  Telegram = 6,
  Ivr = 7
}

export enum CommunicationDispatchStatusIds {
  Standby = 1,
  Sending = 2,
  Sent = 3,
  Error = 4,
  Cancelled = 5
}

export enum CommunicationIdentifierStatusIds {
  AcceptedByProvider = 1,
  ValidationError = 2,
  ProviderError = 3
}

export enum CommunicationProviderTypeIds {
  ExpertSender = 1,
  Divino = 2,
  Firebase = 3,
  Sherlock = 4,
  SmsTraffic = 5,
  GetResponse = 6,
  Smpp = 7,
  OmniChannel = 8
}

export enum CommunicationTemplateTypeIds {
  Value = 1,
  Sql = 2
}

export enum CommunicationTypeIds {
  Bulk = 1,
  Campaign = 2
}
