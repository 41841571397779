import { BaseEntity } from '../BaseEntity';

export interface SegmentType extends BaseEntity {
  Name: string;
}

export enum SegmentTypeIds {
  User = 1,
  Cookie = 2
}
